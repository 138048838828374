<div class="ui container fluid basic segment">
  <div class="ui borderless menu">
    <a class="item" routerLinkActive="active" routerLink="auto-pilot">Auto-pilot mode</a>
    <a class="item" routerLinkActive="active" routerLink="chargeport-simulator">Chargepoint simulator</a>
    <a class="item" routerLinkActive="active" routerLink="load-tester">Load tester</a>
    <a class="item" routerLinkActive="active" routerLink="logs">Logs</a>
    <!-- <a class="item" routerLinkActive="active" routerLink="service-status">Service status</a> -->
    <a class="item" routerLinkActive="active" routerLink="external-api">Customer api status</a>

    <div class="right menu">
      <div class="ui left aligned blue header item">Electriphi</div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
