import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InvokerComponent } from './invoker/invoker.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LogsComponent } from './logs/logs.component';
import { LoadTesterComponent } from './load-tester/load-tester.component';
import { ChargeportSimulatorComponent } from './chargeport-simulator/chargeport-simulator.component';
import { ServiceStatusComponent } from './service-status/service-status.component';
import { ExternalApiComponent } from './external-api/external-api.component';
import { ExternalApiState } from './store/external-api.state';
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    InvokerComponent,
    LogsComponent,
    LoadTesterComponent,
    ChargeportSimulatorComponent,
    ServiceStatusComponent,
    ExternalApiComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    NgxsModule.forRoot([ExternalApiState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
