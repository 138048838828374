import { Component, OnInit } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-chargeport-simulator',
  templateUrl: './chargeport-simulator.component.html',
  styleUrls: ['./chargeport-simulator.component.scss']
})
export class ChargeportSimulatorComponent implements OnInit {

  c = 0;
  possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  id = this.randomId();
  _websocket = null;
  connector_locked = false;
  ssid;
  heartbeat_interval;
  connectorId;
  cstatus;
  errorCode;
  vendorId;
  info;
  vendorErrorCode;
  SN;
  txMap;
  la;
  Auth;

  constructor() { }

  ngOnInit(): void {

    $(document).ready(() => {
      $(".indicator").hide();
      $("#red").show();
      //bind controls
      $("#connect").click(()=> {
        $(".indicator").hide();
        $("#console").html("");
        this.wsConnect();
      });
      $("#send").click(()=> {
        this.Authorize();
      });
      $("#start").click(()=> {
        this.startTransaction();
      });
      $("#stop").click(()=> {
        this.stopTransaction();
      });
      $("#mv").click(()=> {
        sessionStorage.setItem("LastAction", "MeterValues");
        var metervalueSoc = $("#metervalueSoc").val();
        var metervalueEnergyActive = $("#metervalueEnergyActive").val();
        var metervaluePowerActive = $("#metervaluePowerActive").val();
        var metervalueVoltage = $("#metervalueVoltage").val();
        var metervalueCurrentImport = $("#metervalueCurrentImport").val();
        var metervalueTemperature = $("#metervalueTemperature").val();
        var connectorId = parseInt($("#mvconnectorId").val(), 10);
        this.ssid = parseInt(sessionStorage.getItem("TransactionId"));
        var MV = JSON.stringify([
          2,
          this.id,
          "MeterValues",
          {
            connectorId: connectorId,
            transactionId: this.ssid,
            meterValue: [
              {
                timestamp: this.formatDate(new Date()),
                sampledValue: [
                  { measurand: "SoC", value: metervalueSoc, unit: "Percent" },
                  {
                    measurand: "Energy.Active.Import.Register",
                    value: metervalueEnergyActive,
                    unit: "Wh",
                  },
                  {
                    measurand: "Power.Active.Import",
                    value: metervaluePowerActive,
                    unit: "W",
                  },
                  {
                    measurand: "Voltage",
                    value: metervalueVoltage,
                    unit: "V",
                  },
                  {
                    measurand: "Current.Import",
                    value: metervalueCurrentImport,
                    unit: "A",
                  },
                  {
                    measurand: "Temperature",
                    value: metervalueTemperature,
                    unit: "Celsius",
                  },
                ],
              },
            ],
          },
        ]);
        this._websocket.send(MV);
      });

      $("#heartbeat").click(()=> {
        this.send_heartbeat();
      });

      $("#status").click(()=> {
        sessionStorage.setItem("LastAction", "StatusNotification");

        this.connectorId = parseInt($("#snconnectorId").val(), 10);
        this.cstatus = $("#cstatus").val();
        this.errorCode = $("#errorCode").val();
        this.info = $("#info").val();
        this.vendorId = $("#vendorId").val();
        this.vendorErrorCode = $("#vendorErrorCode").val();

        this.SN = JSON.stringify([
          2,
          this.id,
          "StatusNotification",
          {
            connectorId: this.connectorId,
            status: this.cstatus,
            errorCode: this.errorCode,
            info: this.info,
            timestamp: this.formatDate(new Date()),
            vendorId: this.vendorId,
            vendorErrorCode: this.vendorErrorCode,
          },
        ]);
        alert("Request Data : " + this.SN);
        this._websocket.send(this.SN);
      });

      $("#data_transfer").click(()=> {
        sessionStorage.setItem("LastAction", "DataTransfer");
        var DT = JSON.stringify([
          2,
          this.id,
          "DataTransfer",
          {
            vendorId: this.vendorId,
            messageId: "GetChargeInstruction",
            data: "",
          },
        ]);
        alert("Request Data : " + DT);
        this._websocket.send(DT);
      });

      $("#connect").on("change", ()=> {
        if (this._websocket) {
          this._websocket.close(3001);
        }
      });

    });

  }

  formatDate(date) {
    var day = String(date.getDate());
    if (day.length < 2) {
      day = "0" + day.slice(-2);
    }
    var monthIndex = String(date.getMonth() + 1);
    if (monthIndex.length < 2) {
      monthIndex = "0" + monthIndex.slice(-2);
    }
    var year = date.getFullYear();
    var h = date.getHours();
    var m = String(date.getMinutes());
    var s = String(date.getSeconds());
    if (h.length < 2) {
      h = "0" + h.slice(-2);
    }
    if (m.length < 2) {
      m = "0" + m.slice(-2);
    }
    if (s.length < 2) {
      s = "0" + s.slice(-2);
    }
    return (
      year +
      "-" +
      monthIndex +
      "-" +
      day +
      "T" +
      h +
      ":" +
      m +
      ":" +
      s +
      "Z"
    );
  }

  randomId() {
    var id = "";
    for (var i = 0; i < 36; i++) {
      id += this.possible.charAt(Math.floor(Math.random() * this.possible.length));
    }
    return id;
  }

  wsConnect() {
    var wsurl = $("select").val();
    var CP = $("#CP").val();
    if (this._websocket) {
      $("#red").show();
      this._websocket.close(3001);
    } else {
      this._websocket = new WebSocket(wsurl + "" + CP);
      alert(wsurl + "" + CP);
      this._websocket.onopen = (authorizationData) => {
        sessionStorage.setItem("LastAction", "BootNotification");
        $("#yellow").show();
        this.BootNotification();
        $("#connect").text("Disconnect").css("background", "green");
      };
      this._websocket.onmessage = (msg) => {
        this.c++;
        var ddata = JSON.parse(msg.data);
        alert("Response Data : " + msg.data);
        console.log(msg.data);
        if (this.c == 1) {
          var hb_interval = this.handleData(ddata);
          sessionStorage.setItem("Configuration", hb_interval);
        }
        if (ddata[0] === 3) {
          var type = ddata[1];
          this.la = this.getLastAction();
          if (type == "StartTrasnaction") {
            this.txMap = ddata[3];
            alert("TXMAP : " + this.txMap);
            var TransactionId = JSON.parse(this.txMap).transactionId;
            alert("TXId : " + TransactionId);
            sessionStorage.setItem("TransactionId", TransactionId);
          }
          this.logMsg("Response: " + JSON.stringify(ddata[2]));
        } else if (JSON.parse(msg.data)[0] === 4) {
          this.logMsg("Data exchange failed - JSON is not accepted!");
        } else if (JSON.parse(msg.data)[0] === 2) {
          alert("MESSAGE TYPE :" + ddata[2]);
          this.logMsg(JSON.parse(msg.data)[2]);
          this.id = JSON.parse(msg.data)[1];
          switch (ddata[2]) {
            case "Reset":
              //Reset type SOFT, HARD
              var ResetS = JSON.stringify([3, this.id, { status: "Accepted" }]);
              this._websocket.send(ResetS);
              location.reload();
              break;
            case "RemoteStopTransaction":
              //TransactionID
              this.txMap = ddata[3];
              alert("ddata : " + ddata);
              alert("TXMAP : " + this.txMap);
              var TransactionId = this.txMap.transactionId;
              sessionStorage.setItem("TransactionId", TransactionId);
              $(".indicator").hide();
              $("#yellow").show();
              break;
            case "RemoteStartTransaction":
              this.txMap = ddata[3];
              alert("ddata : " + ddata);
              alert("TXMAP : " + this.txMap);
              var TransactionId = this.txMap.chargingProfile.transactionId;
              sessionStorage.setItem("TransactionId", TransactionId);
              break;
            case "SetChargingProfile":
              this.txMap = ddata[3];
              alert("ddata : " + ddata);
              alert("TXMAP : " + this.txMap);
              var TransactionId = this.txMap.csChargingProfiles.transactionId;
              sessionStorage.setItem("TransactionId", TransactionId);
              break;
            case "UnlockConnector": /////////ERROR!!!!!!!!
              //connectorId
              var UC = JSON.stringify([3, this.id, { status: "Accepted" }]);
              this._websocket.send(UC);
              break;
            default:
              var error = JSON.stringify([4, this.id]);
              this._websocket.send(error);
              break;
          }
        }
      };
      this._websocket.onclose =  (evt)=> {
        $("#connect").text("Connect").css("background", "#369");
        if (evt.code == 3001) {
          this.logMsg("ws closed");
          this._websocket = null;
        } else {
          this.logMsg("ws connection error: " + evt.code);
          $("#console").html("");
          this._websocket = null;
          // wsConnect();
        }
      };
      this._websocket.onerror = (evt) => {
        if (this._websocket.readyState == 1) {
          $("#red").show();
          this.logMsg("ws normal error: " + evt.type);
        }
      };
    }
  }

  logMsg(err) {
    console.log(err);
    $("#console").append("<li>" + err + "</li>");
  }

  Authorize() {
    sessionStorage.setItem("LastAction", "Authorize");
    this.Auth = JSON.stringify([
      2,
      this.id,
      "Authorize",
      { idTag: $("#TAG").val() },
    ]);
    alert("Request Data : " + this.Auth);
    this._websocket.send(this.Auth);
  }

  startTransaction() {
    sessionStorage.setItem("LastAction", "startTransaction");
    $(".indicator").hide();
    $("#green").show();
    this.connector_locked = true;
    this.logMsg("Connector status changed to: " + this.connector_locked);
    var strtT = JSON.stringify([
      2,
      "StartTransaction",
      "StartTransaction",
      {
        connectorId: parseInt($("#connectorId").val(), 10),
        idTag: $("#TAG").val(),
        timestamp: this.formatDate(new Date()),
        meterStart: 19000,
        reservationId: 0,
      },
    ]);
    alert("Request Data : " + strtT);
    this._websocket.send(strtT);
  }

  stopTransaction(transaction_id = false) {
    sessionStorage.setItem("LastAction", "stopTransaction");
    transaction_id == false
      ? (this.ssid = parseInt(sessionStorage.getItem("TransactionId")))
      : (this.ssid = transaction_id);
    $(".indicator").hide();
    this.connector_locked = false;
    this.logMsg("Connector status changed to: " + this.connector_locked);
    $("#yellow").show();
    var stpT = JSON.stringify([
      2,
      this.id,
      "StopTransaction",
      {
        transactionId: 0,
        timestamp: this.formatDate(new Date()),
        meterStop: 20,
      },
    ]);
    alert("Request Data : " + stpT);
    this._websocket.send(stpT);
  }

  handleData(data, request = false) {
    var lastAction = this.getLastAction();
    if ((lastAction = "BootNotification")) {
      data = data[2];
      this.heartbeat_interval = data.interval;
      return this.heartbeat_interval;
    } else if ((lastAction = "StartTransaction")) {
      return "StartTransaction";
    }
  }

  getLastAction() {
    var LastAction = sessionStorage.getItem("LastAction");
    return LastAction;
  }

  BootNotification() {
    var BN = JSON.stringify([
      2,
      "BootNotification",
      "BootNotification",
      {
        chargePointVendor: "AVT-Company",
        chargePointModel: "AVT-Express",
        chargePointSerialNumber: "avt.001.13.1",
        chargeBoxSerialNumber: "avt.001.13.1.01",
        firmwareVersion: "0.9.87",
        iccid: "",
        imsi: "",
        meterType: "AVT NQC-ACDC",
        meterSerialNumber: "avt.001.13.1.01",
      },
    ]);
    this.logMsg("ws connected");
    alert("Request Data : " + BN);
    this._websocket.send(BN);
  }

  startHB(interval) {
    this.logMsg("Setting heartbeat interval to " + interval);
    setInterval(this.send_heartbeat, interval);
  }

  send_heartbeat() {
    sessionStorage.setItem("LastAction", "Heartbeat");
    var HB = JSON.stringify([2, this.id, "Heartbeat", {}]);
    alert("Request Data : " + HB);
    this._websocket.send(HB);
  }


}
