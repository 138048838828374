// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiStausUrl: 'http://13.56.164.130:3000',
  firebaseConfig: {
    apiKey: 'AIzaSyD_acTUagwkYYFh9nAo_K-P-QpYhaXA-5g',
    authDomain: 'electriphi-ghost-invoker-dev.firebaseapp.com',
    databaseURL: 'https://electriphi-ghost-invoker-dev.firebaseio.com',
    projectId: 'electriphi-ghost-invoker-dev',
    storageBucket: 'electriphi-ghost-invoker-dev.appspot.com',
    messagingSenderId: '561186580477'
  },
  defaultServerURL: 'http://35.154.245.154/',
  defaultAuthToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJHZU1zIiwiaWF0IjoxNTU1NTc5MTE3LCJleHAiOjE1NTgxNzExMTcsImRhdGEiOnsidXNlciI6IjVjNzU2MDU2ZGNhYzI1NDA4ZTg3NjNkOSIsIm9yZ2FuaXphdGlvbklkIjoiNWM3NTYwNTZkY2FjMjU0MDhlODc2M2Q4IiwiZmxlZXRJZCI6IjVjNzU2MDU2ZGNhYzI1NDA4ZTg3NjNkYyJ9fQ.Yyrjdii0sFrmm7Z51020z7WzgmBFr6Szxr3TdMW1P_g'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
