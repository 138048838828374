<div class="ui container fluid">
  <br>
  <h2 class="ui header">
    Load tester
    <div class="sub header">
      Test the load-handling capacity of API and Database server by running random API querries
    </div>
  </h2>
  <br>
  
  <div class="ui stackable grid">
    <div class="row">
      <div class="seven wide column">

        <div class="ui red raised form segment padded" id="loadTesterForm">
          <div class="required field">
            <label>API server url</label>
            <div class="ui left labeled input">
              <div class="ui basic red label">
                www
              </div>
              <input type="url" value="https://test.electriphi.dev/" name="serverURL"
                placeholder="ex: https://test.electriphi.dev/">
            </div>
          </div>
          <div class="required field">
            <label>
              Authentication token
            </label>
            <div class="ui left labeled input">
              <div class="ui basic red icon label">
                <i class="key icon"></i>
              </div>
              <input type="text"
                value="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJHZU1zIiwiaWF0IjoxNTU1OTQ5NDI0LCJleHAiOjE1NTg1NDE0MjQsImRhdGEiOnsidXNlciI6IjVjODY1MWQwNTAwZTZhNTUzZDdkZGY5ZSIsIm9yZ2FuaXphdGlvbklkIjoiNWM4NjUxY2Y1MDBlNmE1NTNkN2RkZjlkIiwiZmxlZXRJZCI6IjVjODY1MWQwNTAwZTZhNTUzZDdkZGZhMSJ9fQ.9G9z4vm47ZY24EunxY1vchxdeJFCj5P6oxR0IZrzq5M"
                name="authToken" placeholder="qwertyuioasdfghjkl">
            </div>
          </div>
          <div class="required field">
            <label>No. of requests</label>
            <input type="number" name="limit" placeholder="Ex: 100">
          </div>
          <div class="required field">
            <label>Your name</label>
            <div class="ui left labeled input">
              <div class="ui black basic label">
                <i class="user icon"></i>
              </div>
              <input type="text" name="user" placeholder="Enter your name">
            </div>
          </div>
          <button class="ui red basic submit button">Create Vehicles</button>
        </div>
      </div>
      <div class="nine wide column">
        <div class="ui message">
          <p>Logger is under development...</p>
        </div>
      </div>
    </div>
  </div>
</div>