<div class="ui container fluid external-api">
    <div class="flex justify-between margin">
        <h2 class="ui header">External API Status</h2>
        <button class="ui icon basic blue button" (click)="addCustomer()">
            <i class="ui plus icon"></i> &nbsp;
            <strong>Add Customer</strong>
        </button>
    </div>

    <table class="ui grey table accordion very padded">
        <thead class="custom thead">
            <tr class="ui grid">
                <div class="three wide column">
                    <th>Customer name</th>
                </div>
                <div class="four wide column">
                    <th>Instance URL</th>
                </div>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of segregateDocs | keyvalue">
                <div class="ui grid title custom">
                    <div class="three wide column flex">
                        <td><i class="dropdown icon"></i> {{item.value[0].customerName | titlecase}}</td>
                    </div>
                    <div class="four wide column">
                        <td>{{item.value[0].instanceUrl}}</td>
                    </div>
                </div>

                <div class="content">
                    <table class="ui small basic table">
                        <thead>
                            <tr>
                                <th>Customer ID</th>
                                <th>Account ID</th>
                                <th>Access Token</th>
                                <th>Authentication Token</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of item.value"
                                [ngClass]="(item.value[0].status.toLowerCase() === 'active') ? 'positive' : (item.status.toLowerCase() === 'inactive') ? 'negative' : 'warning'">
                                <td>{{row.customerId}}</td>
                                <td>{{row.accountId}}</td>
                                <td title="{{row.accessToken}}">
                                    <label (click)="copyToClipboard(row.accessToken)" data-tooltip="Click to copy">
                                        {{row.accessToken}}
                                    </label>
                                </td>
                                <td title="{{row.instanceAuthToken}}">
                                    <label (click)="copyToClipboard(row.instanceAuthToken)"
                                        data-tooltip="Click to copy">
                                        {{row.instanceAuthToken | slice:0:30}} . . .
                                    </label>
                                </td>
                                <td>{{row.status}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </tr>
        </tbody>
    </table>
</div>

<div class="ui modal" id="addAccountForm">
    <div class="header">
        Add New Account
    </div>
    <div class="content">
        <form class="ui form" [formGroup]="addAccountForm" #AccountForm>
            <div class="field">
                <label>Customer Name</label>
                <input type="text" formControlName="customerName" placeholder="Customer name">
            </div>
            <div class="field">
                <label>Customer ID</label>
                <input type="text" formControlName="customerId" placeholder="Customer ID">
            </div>
            <div class="field">
                <label>Account ID</label>
                <input type="text" formControlName="accountId" placeholder="Account ID">
                <!-- <div class="ui action fluid input">
                    <input type="text" formControlName="accountId" placeholder="Account ID">
                    <button class="ui icon button" (click)="refreshUUID()" disabled>
                        <i class="redo alternate icon"></i>
                    </button>
                </div> -->
            </div>
            <div class="field">
                <label>Instance URL</label>
                <input type="text" formControlName="instanceUrl" placeholder="Instance URL">
            </div>
            <div class="field">
                <label>Authentication Token</label>
                <input type="text" formControlName="authenticationToken" placeholder="Authentication Token">
            </div>
            <div class="field">
                <label>Access Token</label>
                <input type="text" formControlName="accessToken" placeholder="Access Token">
            </div>
            <div class="field">
                <label>Status</label>
                <select class="ui fluid dropdown" formControlName="status">
                    <option value="active">Active</option>
                    <option value="inactive">InActive</option>
                </select>
            </div>
            <div class="ui negative message" *ngIf="errorMessage">
                <p>Please fill valid information</p>
            </div>
        </form>
    </div>
    <div class="actions">
        <button class="ui basic negative button">
            Cancel
        </button>
        <button class="ui blue button" (click)="submitAddAccountForm()">
            Submit
        </button>
    </div>
</div>