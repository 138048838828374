import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { HttpService } from "../shared/http.service";
import { Create, Delete, Fetch, GetOne, Update } from "./external-api.actions";
import { ExternalApi } from "../models/external-api.model";
import { environment } from "src/environments/environment";

export interface ExternalApiStateModel {
    docs: ExternalApi[];
}

@State<ExternalApiStateModel>({
    name: 'externalApi',
    defaults: {
        docs: []
    }
})
@Injectable()
export class ExternalApiState {
    readonly headerOptions = {
        serverURL: environment.apiStausUrl,
        authToken: '',
    }
    readonly root = '/api-status';

    constructor(private http: HttpService) { }

    @Selector()
    static docs(state: ExternalApiStateModel): ExternalApi[] {
        return state.docs
    }

    @Action(Fetch)
    fetchDocuments(ctx: StateContext<ExternalApiStateModel>): any {
        const res = this.http.setBaseURLAndAuthToken(this.headerOptions).get(this.root);

        return res.pipe(
            tap((data: any) => {
                return ctx.patchState({
                    docs: data
                })
            })
        )
    }

    @Action(Create)
    CreateDocument(ctx: StateContext<ExternalApiStateModel>, { payload }: any): any {
        const res = this.http.setBaseURLAndAuthToken(this.headerOptions).post(this.root, payload);

        return res.pipe(tap((data: any) => {
            return ctx.dispatch(new Fetch());
        }))
    }


}