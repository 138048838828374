import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  baseURL;

  options;

  constructor(private http: HttpClient) {}

  setBaseURLAndAuthToken(data) {
    this.baseURL = data.serverURL;
    this.options = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        Authorization: data.authToken,
        Pragma: "no-cache",
        "Cache-Control": "no-store",
        "Content-Type": "application/json",
        "x-api-key": "12345678",
      }),
    };

    return this;
  }

  post(endpoint, data) {
    return this.http.post(`${this.baseURL}${endpoint}`, data, this.options);
  }

  get(endpoint): Observable<any> {
    return this.http.get(`${this.baseURL}${endpoint}`, this.options);
  }

  put(endpoint, data) {
    return this.http.put(`${this.baseURL}${endpoint}`, data, this.options);
  }
}
