import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from "@ngxs/store";
import { Observable } from 'rxjs';
import { ExternalApi } from '../models/external-api.model';
import { Create, Fetch } from '../store/external-api.actions';
import { ExternalApiState } from '../store/external-api.state';
// import { v4 as uuidv4 } from 'uuid';

declare var $: any;

@Component({
  selector: 'app-external-api',
  templateUrl: './external-api.component.html',
  styleUrls: ['./external-api.component.scss']
})

export class ExternalApiComponent implements OnInit {
  addAccountForm: FormGroup;
  errorMessage: boolean;
  segregateDocs: any;

  public doc: Partial<ExternalApi> = {
    customerName: null,
    customerId: null,
    accountId: null,
    instanceUrl: null,
    authenticationToken: null,
    accessToken: null,
    status: 'active'
  }

  @Select(ExternalApiState.docs)
  public docs$: Observable<ExternalApi[]>;

  constructor(private store: Store, private formBuilder: FormBuilder) {
    this.addAccountForm = this.formBuilder.group({
      customerName: [''],
      customerId: ['', Validators.required],
      accountId: ['', Validators.required],
      instanceUrl: ['', Validators.required],
      authenticationToken: ['', Validators.required],
      accessToken: ['', Validators.required],
      status: ['active', Validators.required]
    });
  }


  ngOnInit(): void {
    this.store.dispatch(new Fetch());

    this.docs$.subscribe(res => {
      this.segregateDocs = res?.reduce((r, a) => {
        r[a.customerId] = [...r[a.customerId] || [], a];
        return r;
      }, {});
    })

    // this.addAccountForm.get('customerId').valueChanges.subscribe(d => {
    //   this.addAccountForm.controls['url'].setValue(`https://api.${d}.electriphi.io/${this.addAccountForm.value.accountId}`)
    // });

    // this.addAccountForm.get('accountId').valueChanges.subscribe(d => {
    //   this.addAccountForm.controls['url'].setValue(`https://api.${this.addAccountForm.value.customerId}.electriphi.io/${d}`)
    // });

    $('.ui.accordion').accordion();
  }

  addCustomer(): void {
    this.errorMessage = false;
    $('#addAccountForm').modal({
      centered: false,
      closable: false
    }).modal('show');
  }

  copyToClipboard(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert(`Copied: ${str}`);
  }


  submitAddAccountForm(): void {
    if (this.addAccountForm.status === 'VALID') {
      $('#addAccountForm').modal('hide');
      this.store.dispatch(new Create(this.addAccountForm.value));
      this.addAccountForm.reset();
    } else {
      this.errorMessage = true
    }
  }

  // refreshUUID(): void {
  //   this.addAccountForm.controls['accountId'].setValue(uuidv4());
  // }
}
