<div class="ui basic container inverted segment">
  <h1>Simple OCPP 1.6 Chargebox Simulator</h1>
  <select style="display: none">
    <option value="">OCPP-J1.6</option>
  </select>
  <label>Central Station</label
  ><input
    id="CP"
    type="text"
    placeholder="Id Station"
    value="ws://localhost:8000/ws/BTC001"
  />
  <label>Tag</label
  ><input id="TAG" type="text" placeholder="Tag" value="VTC001" />
  <h2>Actions</h2>
  <button id="connect">Connect</button>
  <button id="send">Authorize</button>
  <label>ConnectorID</label
  ><input id="connectorId" type="text" placeholder="Connector ID" />
  <button id="start">Start Transaction</button>
  <button id="stop">Stop Transaction</button>
  <button id="heartbeat">Heartbeat</button>
  <label>ConnectorID</label
  ><input id="mvconnectorId" type="text" placeholder="Connector ID" />
  <label>Meter value SoC(Percent)</label
  ><input
    id="metervalueSoc"
    type="text"
    placeholder="Meter value SoC"
    value="1"
  />
  <label>Meter value Energy Active(Wh)</label
  ><input
    id="metervalueEnergyActive"
    type="text"
    placeholder="Meter value Energy Active"
    value="400000"
  />
  <label>Meter value Power Active(W)</label
  ><input
    id="metervaluePowerActive"
    type="text"
    placeholder="Meter value Power Active"
    value="50000"
  />
  <label>Meter value Voltage(V)</label
  ><input
    id="metervalueVoltage"
    type="text"
    placeholder="Meter value Voltage"
    value="213"
  />
  <label>Meter value Current Import(A)</label
  ><input
    id="metervalueCurrentImport"
    type="text"
    placeholder="Meter value Current Import"
    value="240"
  />
  <label>Meter value Temperature(Celsius)</label
  ><input
    id="metervalueTemperature"
    type="text"
    placeholder="Meter value Temperature"
    value="30"
  />
  <button id="mv">Send Meter Values</button>

  <label>ConnectorID</label>
  <input id="snconnectorId" type="text" placeholder="Connector ID" />
  <label>Status</label><input id="cstatus" type="text" placeholder="Status" />
  <label>ErrorCode</label
  ><input id="errorCode" type="text" placeholder="Error Code" />
  <label>Information</label
  ><input id="info" type="text" placeholder="Information" />
  <label>VendorId</label
  ><input id="vendorId" type="text" placeholder="Vendor ID" />
  <label>VendorErrorCode</label
  ><input id="vendorErrorCode" type="text" placeholder="Vendor ErrorCode" />

  <button id="status">Status Notification</button>
  <button id="data_transfer">Data Tranfer</button>

  <div>
    <span class="indicator" id="red">____</span>
    <span class="indicator" id="green">____</span>
    <span class="indicator" id="blue">____</span>
    <span class="indicator" id="yellow">____</span>
  </div>
  <ul id="console"></ul>
</div>
