import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PortService {

  availablePorts = [];
  constructor(private http: HttpService) { }

  refreshPorts() {
    this.http.get('charging-port-vehicles/free-charge-ports').subscribe((response: any) => {
      this.availablePorts = response.docs
    });
  }

  removePort(portId) {
    this.availablePorts = this.availablePorts.filter(item => { return item != portId })
  }

  addPort(portId) {
    this.availablePorts.unshift(portId);
  }

  getPorts(count) {
    let length = this.availablePorts.length
    if (length - count >= 0) {
      return this.availablePorts.slice((length - count), length);
    } else {
      //alert(`Not enough available to connect ${count} vehicles`);
      console.log(`Not enough ports available to connect ${count} vehicles`)
    }
  }
}
