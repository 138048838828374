import { Component, OnInit } from '@angular/core';
import { Log } from '../invoker/invoker.component';
import { LoggerService } from '../shared/logger.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  logs: Log[] = [];

  constructor(private logger: LoggerService) { }

  ngOnInit() {
    this.logger.getAllLogs(50).subscribe((logs) => {
      this.logs = logs;
    });
  }

}
