<div class="ui container fluid">
  <div class="ui secondary two item menu">
    <h3 class="header violet active item">
      Now {{userDetails?.name}} is running it for
      {{userDetails?.vehicles}} vehicle/s
    </h3>
    <div class="item">
      <div class="ui secondary header">Vehicle connection logs</div>
    </div>
  </div>
  <div class="ui stackable grid very padded">
    <div class="two column row">
      <div class="column">
        <div class="ui red raised segment form padded" id="invokerForm">
          <div class="required field">
            <label>API server url</label>
            <div class="ui left labeled input">
              <div class="ui basic red label">
                www
              </div>
              <input type="url" value="https://api.demo.electriphi.io/" name="serverURL"
                placeholder="ex: https://api.demo.electriphi.io/">
            </div>
          </div>
          <div class="field">
            <label>
              Authentication token (Include "Bearer")
            </label>
            <div class="ui left labeled input">
              <div class="ui basic red icon label">
                <i class="key icon"></i>
              </div>
              <input type="text"
                value="Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFwcGFkbWluQGVsZWN0cmlwaGkuYWkiLCJzdWIiOiI1ZjQzYTlmMzc4NzM4MzU4ODY5Njg3OTUiLCJyb2xlIjoic3VwZXJBZG1pbiIsIm9yZ2FuaXphdGlvbklkIjp7ImFjdGl2ZSI6dHJ1ZSwiY2FsbElkIjpudWxsLCJkZWxldGVkQXQiOm51bGwsIl9pZCI6IjVmNDNhOWYzNzg3MzgzNTg4Njk2ODc5NCIsIm9yZ2FuaXphdGlvbk5hbWUiOiJFbGVjdHJpcGhpIEluYyIsImFkZHJlc3MiOiIxMTU1IEluZGlhbmEgU3QsIFNhbiBGcmFuY2lzY28sIENBIDk0MTA3IiwiY3JlYXRlZEF0IjoiMjAyMC0wOC0yNFQxMTo1MjoxOS43NTBaIiwidXBkYXRlZEF0IjoiMjAyMC0wOC0yNFQxMTo1MjoxOS43NTBaIiwiX192IjowfSwiYWN0aXZlIjp0cnVlLCJuYW1lIjoiVmluYXlhayBTaGl2YXB1amUiLCJmbGVldElkIjoiNWY0M2JiN2E0MDM5OGY1OWU4YmQ2MTlkIiwiaWF0IjoxNjA2NzUzMzA1LCJleHAiOjE2MDY4Mzk3MDUsImlzcyI6IkVsZWN0cmlwaGkgSW5jLiIsImp0aSI6IkVMRUNULTEifQ.NWcNzBpbBy5PXeSjiU4H0uDtNYotOJNtz8tUI5ZKuEuibDR9hVB85XQrSGSINY45rXLG9ojgEYrbcZT8iMz2yg"
                name="authToken" placeholder="authToken">
            </div>
          </div>
          <div class="field">
            <label>
              FleetId
            </label>
            <div class="ui left labeled input">
              <div class="ui basic red icon label">
                ID
              </div>
              <input type="text" value="5f43bb7a40398f59e8bd619d" name="fleetId" placeholder="fleetId">
            </div>
          </div>
          <div class="two fields">
            <div class="disabled field">
              <label>Vehicles limit</label>
              <input type="number" name="limit" value="1" placeholder="Vehicles to connect">
            </div>
            <div class="field">
              <label>Delay</label>
              <div class="ui left labeled input">
                <div class="ui basic red label">
                  <i class="clock outline icon"></i>
                </div>
                <input type="number" name="delay" placeholder="in minutes">
              </div>
            </div>
          </div>
          <div class="field">
            <div class="ui left labeled input">
              <div class="ui black basic label">
                <i class="user icon"></i>
              </div>
              <input type="text" name="user" placeholder="Enter your name">
            </div>
          </div>
          <div class="ui inverted red submit button" (click)="submitForm()">Init script</div>
          <span style="float: right; color:rgb(177, 56, 56);">
            * Make sure Only one user is running it at a time.
          </span>
        </div>

        <div class="ui basic segment" *ngIf="showConnectVehiclesButton">
          <div class="ui green message">
            <p>Ghost script started</p>
          </div>
          <div class="ui two grouped buttons">
            <button class="ui teal button" (click)="getRoutes()">Generate schedules</button>
            <button class="ui black button" (click)="clearInterval()">Stop script</button>
          </div>
        </div>
      </div>

      <div class="column" style="height: 70vh; overflow: auto;">
        <table class="ui tiny basic red table very padded" *ngIf="connectedVehicles?.length > 0">
          <thead>
            <th>VehicleId</th>
            <th>PortId</th>
            <th>User</th>
            <th>Time</th>
          </thead>
          <tbody>
            <tr *ngFor="let vehicle of connectedVehicles" class="{{vehicle.isConnected? 'positive' : 'error'}}">
              <td>
                {{vehicle.vehicleId}}
              </td>
              <td>
                {{vehicle.portId}}
              </td>
              <td>
                {{vehicle.user}}
              </td>
              <td>
                {{vehicle.timestamp | date: 'short'}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>