import { Component, OnInit } from "@angular/core";

import { HttpService } from "../shared/http.service";

export interface Service {
  instance: string;
  clientUrl?: string;
  apiUrl?: string;
  apiServer: boolean;
  nginx: boolean;
  redis: boolean;
  ocpp: boolean;
  centralServer8080: boolean;
  centralServer8000: boolean;
  chargePoint8080: boolean;
  overallStatus?: boolean;
  customerInstance?: boolean;
  timestamps?: any;
}

@Component({
  selector: "app-service-status",
  templateUrl: "./service-status.component.html",
  styleUrls: ["./service-status.component.scss"],
})
export class ServiceStatusComponent implements OnInit {
  readonly ServerURL = "https://api.operations.electriphi.dev/platform";
  readonly root = "/service-status";

  customerInstanceData: Partial<Service[]> = [];
  data: Partial<Service[]> = [];
  onEveryMinute;

  constructor(private http: HttpService) {}

  ngOnInit(): void {
    this.getServicesStatus();
    this.onEveryMinute = setInterval(() => {
      this.getServicesStatus();
    }, 30000);
  }

  getServicesStatus() {
    const tempObj = {
      serverURL: this.ServerURL,
      authToken: "",
    };

    this.http
      .setBaseURLAndAuthToken(tempObj)
      .get(this.root)
      .subscribe((res) => {
        this.data = res.data;
        this.customerInstanceData = [];
        this.updateOverallStatus();
      });
  }

  updateOverallStatus() {
    const options: any = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    this.data.forEach((d, index) => {
      if (
        this.customerInstanceCheck(d) &&
        d.apiServer &&
        d.nginx &&
        d.ocpp &&
        d.redis
      ) {
        this.data[index].overallStatus = true;
      }
      this.data[index].timestamps = new Date(
        Number(this.data[index].timestamps) * 1000
      ).toLocaleString("en-US", options);
    });
  }

  customerInstanceCheck(data): boolean {
    if (data.customerInstance) {
      this.customerInstanceData.push(data);
      return data.centralServer8000;
    } else return data.centralServer8080 && data.chargePoint8080;
  }

  pageReload(): void {
    location.reload();
  }

  ngOnDestroy(): void {
    clearInterval(this.onEveryMinute);
  }
}
