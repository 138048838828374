<div class="ui container">
  <br>
  <h2 class="header item">Logs</h2>
  <table *ngIf="logs.length > 0" class="ui small basic table">
    <thead>
      <tr>
        <th>
          Timestamp
        </th>
        <th>
          Message
        </th>
        <th>
          User
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of logs" class="{{log.color}}">
        <td>
          {{log.timestamp | date: 'short'}}
        </td>
        <td>
          {{log.message}}
        </td>
        <td>
          {{log.user}}
        </td>
      </tr>
    </tbody>
  </table>
</div>