import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { DataService } from '../shared/data.service';
import { LoggerService } from '../shared/logger.service';
declare const $: any;
@Component({
  selector: 'app-load-tester',
  templateUrl: './load-tester.component.html',
  styleUrls: ['./load-tester.component.scss']
})

export class LoadTesterComponent implements OnInit {

  limit;
  constructor(private http: HttpService, private ds: DataService, private log: LoggerService) { }

  ngOnInit() {
    $('#loadTesterForm').form({
      fields: {
        serverURL: ['empty'],
        limit: ['number', 'empty'],
        authToken: 'empty',
        user: ['empty']
      },
      onSuccess: () => {
        let values = $('#loadTesterForm').form('get values');
        $('#loadTesterForm').addClass('loading');
        this.initScript(values);
      }
    });
  }

  initScript(values) {
    // initializing the server credentials
    this.http.setBaseURLAndAuthToken(values);
    this.limit = values.limit;
    this.log.addLog({ user: values.user, message: 'Starting load testing for ' + values.serverURL, color: 'green' });

    // update userDetails
    this.ds.updateUserStatus({ server: values.serverURL, authTokenUsed: values.authToken, name: values.user, isActive: true, vehicles: values.limit });

    this.createVehicles(values.limit);
  }

  createVehicles(limit) {
  // Needs to be implemented
  }
}
