import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User, VehiclePort } from '../invoker/invoker.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private afs: AngularFirestore) { }

  getUser():Observable<User>{
    return this.afs.collection('globalConfigs').doc<User>('liHC8lxoInJLXVYAQIEC').valueChanges()
  }

  updateUserStatus(userData) {
    this.afs.collection('globalConfigs').doc('liHC8lxoInJLXVYAQIEC').update(userData);
  }

  AddPortVehicle(vehiclePort: VehiclePort) {
    this.afs.collection('vehiclePort').add(vehiclePort).catch(error => console.log(`error while logging ${error.message}`));
  }

  getPortVehicles(): Observable<VehiclePort[]> {
    return this.afs.collection<VehiclePort>('vehiclePort', ref => ref.orderBy('timestamp', 'desc').limit(10)).valueChanges();
  }
}
