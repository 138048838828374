export enum ActionType {
    FETCH_DOCUMENTS = "[EXTERNAL-API] Fetch",
    GET_ONE = '[EXTERNAL-API] Get One',
    CREATE_DOCUMENT = '[EXTERNAL-API] Create',
    UPDATE_DOCUMENT = '[EXTERNAL-API] Update',
    DELETE_DOCUMENT = '[EXTERNAL-API] Delete'
}

export class Fetch {
    public static readonly type = ActionType.FETCH_DOCUMENTS;
}

export class GetOne {
    public static readonly type = ActionType.GET_ONE;
    constructor(public payload: any) {}
}

export class Create {
    public static readonly type = ActionType.CREATE_DOCUMENT;
    constructor (public payload: any) {}
}

export class Update {
    public static readonly type = ActionType.UPDATE_DOCUMENT;
    constructor(public payload: any) {}
}

export class Delete {
    public static readonly type = ActionType.DELETE_DOCUMENT;
    constructor(public payload: any) {}
}