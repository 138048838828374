<div class="ui container service-staus">
    <div class="flex margin">
        <h2>Service Status</h2>
        <button class="ui right labeled icon basic blue mini button" (click)="pageReload()">
            <i class="ui icon redo alternate"></i>
            <strong>Refresh</strong>
        </button>
    </div>

    <h3>Customer instances</h3>
    <div class="ui stackable grid Customer-instances" style="justify-content: space-between;">
        <div class="ui active inverted dimmer" *ngIf="!data.length">
            <div class="ui large text loader">Loading . . . </div>
        </div>
        <div class="eight wide tablet five wide computer column" *ngFor="let item of customerInstanceData">
            <div class="ui card" [ngClass]="item.overallStatus ? 'green-top': 'red-top'" *ngIf="item?.customerInstance">
                <!-- green-top / red-top -->
                <div class="content">
                    <div class="status-icon">
                        <i class="ui circle big icon"
                            [ngClass]="item.overallStatus ? 'check green': 'exclamation red'"></i>
                        <!-- check (green)/ exclamation (red) -->
                    </div>

                    <div class="instance-data">
                        <p>Overall status: &nbsp;
                            <span [ngClass]="item.overallStatus ? 'green' : 'red'">{{item.overallStatus ? 'Active' :
                                'InActive'}}</span>
                        </p>
                        <!-- Active (green) / InActive (red) -->
                        <h3>{{item.instance | titlecase}}</h3>

                        <div class="description">
                            <p>Last check: &nbsp; <span>{{item.timestamps}}</span></p>
                            <p>Api server: &nbsp; <span [ngClass]="item.apiServer ? 'green' : 'red'">{{item.apiServer ?
                                    'Active' :
                                    'InActive' }}</span>
                                <a href="{{item.apiUrl}}" target="_blank"> &nbsp; <i
                                        class="ui telegram plane icon"></i></a>
                            </p>
                            <p>Nginx: &nbsp; <span [ngClass]="item.nginx ? 'green' : 'red'">{{item.nginx ? 'Active'
                                    : 'InActive'}} </span></p>
                            <p>Redis: &nbsp; <span [ngClass]="item.redis ? 'green' : 'red'">{{item.redis ? 'Active'
                                    : 'InActive'}} </span></p>
                            <p>OCPP: &nbsp; <span [ngClass]="item.ocpp ? 'green' : 'red'">{{item.ocpp ? 'Active' :
                                    'InActive'}}</span></p>
                            <p>Centralserver 8000: &nbsp; <span
                                    [ngClass]="item.centralServer8000 ? 'green' : 'red'">{{item.centralServer8000 ?
                                    'Active' : 'InActive'}} </span>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- <div class="extra content">
                    <button class="ui right labeled icon basic orange mini button right floated"
                        disabled="{{item.overallStatus}}">
                        <i class="ui icon exclamation"></i>
                        <strong>Restart</strong>
                    </button>
                </div> -->
            </div>
        </div>
    </div>

    <br>
    <div class="ui divider"></div>

    <h3>Internal instances</h3>
    <div class="ui stackable grid" style="justify-content: space-between;">
        <div class="eight wide tablet five wide computer column" *ngFor="let item of data">
            <div class="ui card" [ngClass]="item.overallStatus ? 'green-top': 'red-top'"
                *ngIf="!item?.customerInstance">
                <!-- green-top / red-top -->
                <div class="content">
                    <div class="status-icon">
                        <i class="ui circle big icon"
                            [ngClass]="item.overallStatus ? 'check green': 'exclamation red'"></i>
                        <!-- check (green)/ exclamation (red) -->
                    </div>

                    <div class="instance-data">
                        <p>Overall status: &nbsp;
                            <span [ngClass]="item.overallStatus ? 'green' : 'red'">{{item.overallStatus ? 'Active' :
                                'InActive'}}</span>
                        </p>
                        <!-- Active (green) / InActive (red) -->
                        <h3>{{item.instance | titlecase}} Instance</h3>

                        <div class="description">
                            <p>Last check: &nbsp; <span>{{item.timestamps}}</span></p>
                            <p>Api server: &nbsp; <span [ngClass]="item.apiServer ? 'green' : 'red'">{{item.apiServer ?
                                    'Active' : 'InActive' }}</span>
                                <a href="{{item.apiUrl}}" target="_blank"> &nbsp; <i
                                        class="ui telegram plane icon"></i></a>
                            </p>
                            <p>Nginx: &nbsp; <span [ngClass]="item.nginx ? 'green' : 'red'">{{item.nginx ? 'Active' :
                                    'InActive'}} </span></p>
                            <p>Redis: &nbsp; <span [ngClass]="item.redis ? 'green' : 'red'">{{item.redis ? 'Active' :
                                    'InActive'}} </span></p>
                            <p>OCPP: &nbsp; <span [ngClass]="item.ocpp ? 'green' : 'red'">{{item.ocpp ? 'Active' :
                                    'InActive'}}</span></p>
                            <p>Centralserver 8080: &nbsp; <span
                                    [ngClass]="item.centralServer8080 ? 'green' : 'red'">{{item.centralServer8080 ?
                                    'Active' : 'InActive'}}</span></p>
                            <p>Chargepoint 8080: &nbsp; <span
                                    [ngClass]="item.chargePoint8080 ? 'green' : 'red'">{{item.chargePoint8080 ? 'Active'
                                    : 'InActive'}}</span></p>
                        </div>
                    </div>
                </div>

                <!-- <div class="extra content">
                    <button class="ui right labeled icon basic orange mini button right floated"
                        disabled="{{item.overallStatus}}">
                        <i class="ui icon exclamation"></i>
                        <strong>Restart</strong>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</div>