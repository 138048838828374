import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvokerComponent } from './invoker/invoker.component';
import { LogsComponent } from './logs/logs.component';
import { LoadTesterComponent } from './load-tester/load-tester.component';
import { ChargeportSimulatorComponent } from './chargeport-simulator/chargeport-simulator.component';
import { ServiceStatusComponent } from './service-status/service-status.component';
import { ExternalApiComponent } from './external-api/external-api.component';

const routes: Routes = [
  {
    path: 'auto-pilot', component: InvokerComponent
  },
  {
    path: 'chargeport-simulator', component: ChargeportSimulatorComponent
  },
  {
    path: 'service-status', component: ServiceStatusComponent
  },
  {
    path: 'logs', component: LogsComponent
  },
  {
    path: 'load-tester', component: LoadTesterComponent
  },
  {
    path: 'external-api', component: ExternalApiComponent
  },
  {
    path: '', redirectTo: 'auto-pilot', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
