import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { PortService } from '../shared/port.service';
import { LoggerService } from '../shared/logger.service';
import { DataService } from '../shared/data.service';

declare const $: any;
declare const moment: any;

export interface Log {
  message: string;
  color: string;
  user: string;
}

export interface VehiclePort {
  vehicleId: string;
  portId: string;
  user: string;
  timestamp: number;
  isConnected: boolean;
}

export interface User {
  name: string;
  server: string;
  authTokenUsed: string;
  isActive: boolean;
  vehicles: number;
}

@Component({
  selector: 'app-invoker',
  templateUrl: './invoker.component.html',
  styleUrls: ['./invoker.component.scss']
})
export class InvokerComponent implements OnInit {

  autoConnectInterval;
  autoDisconnectInterval;

  routes = [];
  drivers = [];
  randomVehicles = [];
  randomSchedules = [];
  scheduledVehicles = [];
  vehiclesToSchedule = [];
  randomPorts = [];
  autoSchedule = [];
  showConnectVehiclesButton = false;
  limit;
  connectedVehicles: VehiclePort[];
  userDetails: User;
  fleetId: "";

  isAnotherUserActive = false;

  constructor(private http: HttpService, private ds: DataService, private portService: PortService, private log: LoggerService) { }

  ngOnInit() {
    this.ds.getUser().subscribe((data: User) => {
      this.userDetails = data;
      if (this.userDetails.isActive) {
        this.isAnotherUserActive = true;
      } else {
        this.isAnotherUserActive = false;
      }
    });

    this.ds.getPortVehicles().subscribe(connectVehicles => {
      this.connectedVehicles = connectVehicles;
    });
  }

  submitForm() {
    $('#invokerForm').form({
      fields: {
        serverURL: ['empty'],
        limit: ['number', 'empty'],
        authToken: 'empty',
        user: ['empty'],
        fleetId: 'empty',
      },
      onSuccess: () => {
        let values = $('#invokerForm').form('get values');
        $('#invokerForm').addClass('loading');
        this.initScript(values);
      }
    });
  }

  initScript(values) {
    // initializing the server credentials
    this.http.setBaseURLAndAuthToken(values);
    this.limit = values.limit;
    this.fleetId = values.fleetId;
    this.log.addLog({ user: this.userDetails.name, message: 'Starting automatic simulation for ' + values.serverURL, color: 'green' });

    this.portService.refreshPorts();

    // update userDetails
    this.ds.updateUserStatus({ server: values.serverURL, authTokenUsed: values.authToken, name: values.user, isActive: true, vehicles: values.limit });

    this.processSchedules()

    this.autoDisconnectInterval = setInterval(() => this.getChargeCompletedVehicles(), 100000);
    this.autoConnectInterval = setInterval(() => this.getVehicles(), 90000);
    this.showConnectVehiclesButton = true;
  }

  processSchedules() {
    this.http.get('schedules?limit=50').subscribe((response: any) => {
      this.log.addLog({ user: this.userDetails.name, message: 'Updating existing schedules..', color: 'violet' });
      this.randomSchedules = response.docs;
      response.docs.forEach(schedule => {

        let driverId = schedule.driverId._id;
        let routeId = schedule.routeId._id;
        let vehicleId = schedule.vehicleId._id;
        let arrivalTime = moment().toISOString();
        let departureTime = moment(arrivalTime).add(24, 'hours').toISOString();
        let params = { driverId, routeId, vehicleId, arrivalTime, departureTime };
        this.http.put(`schedules/${schedule._id}`, params).subscribe((response) => {
          if (response) {
            console.log(response);
            this.log.addLog({ user: this.userDetails.name, message: 'Schedule updated!!', color: 'green' });
          }
        });
      });
    });
  };

  // get all vehicles which are not charging| takes a flag if it invoked for scheduling purpose
  getVehicles() {
    this.log.addLog({ user: this.userDetails.name, message: 'Getting vehicle details..', color: 'violet' });
    this.http.get(`charging-port-vehicles/${this.fleetId}/free-vehicles`).subscribe((response: any) => {
      this.randomVehicles = response.docs;
      this.connectVehicles();
    })
  }

  // Connect all vehicles
  connectVehicles = () => {

    let vehicles = this.randomVehicles;
    // console.log(vehicles)
    let ports = this.portService.getPorts(this.limit);
    // console.log(ports)

    // limit the number of vehicles to be connected
    vehicles = vehicles.slice(0, this.limit);
    // console.log(vehicles)

    if (vehicles && ports) {
      if (vehicles.length && ports.length && ports.length == vehicles.length) {
        vehicles.forEach(async (vehicle) => {

          let portId = ports[Math.floor(Math.random() * 100) % ports.length];

          // 5 second delay between every connect
          await this.delay(5000);

          // Making sure ports wont get repeated
          ports = ports.filter(item => { return item != portId} )

          await this.connectSingleVehicle(vehicle, portId);
        });
      }
    } else {
      this.log.addLog({ color: 'red', user: this.userDetails.name, message: 'No more vehicles or not enough ports left for the vehicles to connect' });
    }
  }

  // connects a single vehicle to the port
  connectSingleVehicle = async (vehicle, portId) => {

    this.log.addLog({ user: this.userDetails.name, message: `Connecting vehicle ${vehicle} to ${portId}`, color: 'yellow' });

    let body = {
      vehicleId: vehicle,
      portId: portId,
      vehicleConnectTime: moment(new Date()).format(),
      fleetId: this.fleetId,
    }

    await this.http.post('charging-port-vehicles', body).subscribe((response) => {
      if (response) {
        this.portService.removePort(body.portId);
        this.ds.AddPortVehicle({ vehicleId: body.vehicleId, portId: body.portId, isConnected: true, user: this.userDetails.name, timestamp: new Date().getTime() })
      }
    });

  }

  // Get routes only while generating schedules
  getRoutes() {
    this.log.addLog({ user: this.userDetails.name, message: 'Getting routes...', color: 'teal' });
    this.http.get('randomRoutes').subscribe((response: any) => {
      this.routes = response.routeIDs;
      this.log.addLog({ user: this.userDetails.name, message: `routeIDs: ${response.routeIDs}`, color: 'teal' });
      this.getDrivers();
    });
  }

  // Get drivers only while generating schedules
  getDrivers() {
    this.log.addLog({ user: this.userDetails.name, message: 'Getting drivers...', color: 'teal' });
    this.http.get('drivers').subscribe((response: any) => {
      this.drivers = response.driverIDs
      this.log.addLog({ user: this.userDetails.name, message: `driverIDs: ${response.driverIDs}`, color: 'teal' });
      this.generateScehdule();
    });
  }

  generateScehdule() {

    let vehicleData = this.vehiclesToSchedule, drivers = this.drivers, routes = this.routes;
    let promiseResult = [], promiseArray = []

    let arrivalTime = moment().format();
    let departureTime = moment(arrivalTime).add(6, 'hours').format();

    vehicleData.forEach((vehicle, i) => {

      // Randomly select a driver
      let driverId = drivers[Math.floor(Math.random() * 100) % drivers.length];

      // Randomly select a route
      let routeId = routes[Math.floor(Math.random() * 100) % routes.length];

      let params = { driverId, routeId, vehicleId: vehicle, arrivalTime, departureTime };

      promiseArray[i] = new Promise(async (resolve, reject) => {

        this.http.post('schedules', params).subscribe(async (schedule) => {
          if (schedule) {
            promiseResult.push(schedule)
            resolve()
          } else {
            reject()
          }
        });

      });
      departureTime = moment(departureTime).add(20, 'minutes').format()
    })

    Promise.all(promiseArray)
      .then(() => {
        this.log.addLog({ user: this.userDetails.name, message: 'Schedules generated!!', color: 'green' });
      }).catch((err) => console.log(err));
  }

  // Generate schedules for all vehicles with no schedules for the day
  generateAutoScehdules() {

    let vehicleData = this.randomVehicles, drivers = this.drivers, routes = this.routes;
    let promiseResult = [], promiseArray = []

    let arrivalTime = moment().format();
    let departureTime = moment(arrivalTime).add(5, 'hours').format();

    vehicleData.forEach((vehicle, i) => {
      // Randomly select a driver
      let driverId = drivers[Math.floor(Math.random() * 100) % drivers.length];

      // Randomly select a route
      let routeId = routes[Math.floor(Math.random() * 100) % routes.length];

      let params = { driverId, routeId, vehicleId: vehicle, arrivalTime, departureTime };

      promiseArray[i] = new Promise(async (resolve, reject) => {

        this.http.post('schedules', params).subscribe(async (schedule) => {
          if (schedule) {
            promiseResult.push(schedule);
            resolve();
          } else {
            reject();
          }
        });

      });

      departureTime = moment(departureTime).add(20, 'minutes').format();

    });

    Promise.all(promiseArray)
      .then(() => {
        this.log.addLog({ user: this.userDetails.name, message: 'Schedules generated!!', color: 'green' });
      }).catch((err) => console.log(err));

  }

  getChargeCompletedVehicles() {
    this.http.get('Charge_Port_Battery/get_charge_ports_vehicle/status?type=Charging Complete').subscribe((res: any) => {
      if (res.docs[0]) {
          this.http.get('schedules').subscribe((response: any) => {
          response.docs.forEach(schedule => {
            if (schedule.vehicleId._id == res.docs[0].vehicleId._id) {
              let driverId = schedule.driverId._id;
              let routeId = schedule.routeId._id;
              let vehicleId = schedule.vehicleId._id;
              let arrivalTime = moment().toISOString();
              let departureTime = moment(arrivalTime).add(4, 'hours').toISOString();
              let params = { driverId, routeId, vehicleId, arrivalTime, departureTime };
              this.http.put(`schedules/${schedule._id}`, params).subscribe((response) => {
                if (response) {
                  this.log.addLog({ user: this.userDetails.name, message: 'Schedule updated!!', color: 'green' });
                }
              });
            }
          });
        });
        //let payload = { vehicleId: res[0].vehicleId.id, portId: res[0].portId.id }
        let payload = { chargingPortVehicle: res.docs[0]._id, vehicleId: res.docs[0].vehicleId._id, portId: res.docs[0].portId._id }
        this.disconnectVehicle(payload);
      }
    });
  }

  disconnectVehicle(portVehicle) {
    //this.http.put('CentralServer/disconnectVehicle', { vehicleId: portVehicle.vehicleId, portId: portVehicle.portId }).subscribe(async (response) => {
    this.http.put(`charging-port-vehicles/${portVehicle.chargingPortVehicle}/disconnect`, portVehicle.chargingPortVehicle ).subscribe(async (response) => {
      if (response) {
        this.ds.AddPortVehicle({ vehicleId: portVehicle.vehicleId, portId: portVehicle.portId, isConnected: false, user: this.userDetails.name, timestamp: new Date().getTime() })
        this.log.addLog({ user: this.userDetails.name, color: 'orange', message: `Vehicle ${portVehicle.vehicleId} is disconnected from port ${portVehicle.portId}` });
        await this.delay(60000);
        this.portService.addPort(portVehicle.portId);
        this.log.addLog({ user: this.userDetails.name, color: 'orange', message: `Port ${portVehicle.portId} is available for connecting next vehicle` });
      }
    });
  }

  getScheduledVehicles() {
    this.http.get('Charge_Port_Battery/get_charge_ports_vehicle/status?type=Scheduled').subscribe((res) => {
      if (res[0]) {
        let payload = { vehicleId: res[0].vehicleId.id, portId: res[0].portId.id }
        this.disconnectVehicle(payload);
      }
    });
  }

  // Reset interval of autoconnect and autoDisconnect script
  clearInterval() {
    clearInterval(this.autoConnectInterval);
    clearInterval(this.autoDisconnectInterval);
    $('#invokeForm').removeClass('loading');
  }

  // Delay function
  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
