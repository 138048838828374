import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Log } from '../invoker/invoker.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private afs: AngularFirestore) { }

  addLog(log: Log) {
    this.afs.collection('logs').add({ color: log.color, message: log.message, timestamp: new Date().getTime(), user: log.user }).catch(error => console.log(`error while logging ${error.message}`));
  }

  getAllLogs(limit): Observable<Log[]> {
    return this.afs.collection<Log>('logs', ref => ref.orderBy('timestamp', 'desc').limit(limit)).valueChanges();
  }

}
